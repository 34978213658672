import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Row, Title, Content, CardsContainer,
} from './styledComponents';
import Dots from '../home/v3/Dots';
import CardSelector from './CardSelector';

import houseNavy from '../../assets/platform/location/house.navy.png';
import houseWhite from '../../assets/platform/location/house.white.png';
import storeNavy from '../../assets/platform/location/store.navy.png';
import storeWhite from '../../assets/platform/location/store.white.png';

const choices = [
  {
    value: 'atShop',
    label: 'Réserver un passage en magasin',
    subLabel: 'Je réserve la venue d’un couturier en magasin pour les retouches de mes clients déjà épinglées',
    images: { white: storeWhite, navy: storeNavy },
  },
  {
    value: 'atCustomer',
    label: 'Prendre RDV à domicile',
    subLabel: 'Je prends RDV pour mon client à son domicile avec un couturier expert',
    images: { white: houseWhite, navy: houseNavy },
  },
];

const Container = styled(CardsContainer)`
  flex-direction: column;
  align-items: center;
`;

const Step1Rdv1Location = ({ selectRdv1Location, selectedRdv1Location }) => (
  <Row>
    <Content>
      <Title>Service à domicile ou Pick Up ?</Title>
      <Dots justifyContent="center" noMargin />
      <Row>
        <Container center>
          {choices.map((piece) => (
            <CardSelector
              key={piece.value}
              slug={piece.value}
              image={selectedRdv1Location === piece.value ? piece.images.white : piece.images.navy}
              alt={piece.label}
              label={piece.label}
              subLabel={piece.subLabel}
              onClick={selectRdv1Location}
              isActive={selectedRdv1Location === piece.value}
              hasLogoImage
              large
            />
          ))}
        </Container>
      </Row>
    </Content>
  </Row>
);

Step1Rdv1Location.propTypes = {
  selectRdv1Location: PropTypes.func.isRequired,
  selectedRdv1Location: PropTypes.string,
};

Step1Rdv1Location.defaultProps = {
  selectedRdv1Location: null,
};

export default Step1Rdv1Location;
