import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getTreeProblems } from '../../services/treeSelector';
import {
  Wrapper, Block, PageTitle, mobileThresholdPixels,
} from './styledComponents';
import { StepContainer as StepContainerV3, ComponentsContainer, colors as colorsV3 }
  from '../home/v3/styledComponents';
import { Button } from '../home/v2/StyledComponents';
import ArrowButton from './LogoButton';
import ProgressBar from './ProgressBar';

import Step1Rdv1Location from './Step1Rdv1Location';
import Step1Pieces from './Step1Pieces';
import Step1Fabrics from './Step1Fabrics';
import Step1Problems from './Step1Problems';
import Step1Locations from './Step1Locations';
import Step1Multiple from './Step1Multiple';
import Step1Lining from './Step1Lining';

import clothes from './clothes';
import tree from './tree';

import featureSwitch from '../../services/featureSwitch';
import ProgressBarVertical from './ProgressBarVertical';

const createRange = (min, max) => {
  const range = [];
  for (let i = min; i < max + 1; i += 1) {
    range.push({ value: i, label: i.toString() });
  }
  return range;
};

const Relative = styled.div`
  position: relative;
`;

const ScrollingPosition = styled.div`
  position: absolute;
  top: -43px;
  @media (max-width: ${mobileThresholdPixels}) {
    ${({ mobileLow }) => mobileLow && 'top: -103px;'}
  }
`;

const StepContainer2 = styled(StepContainerV3)`
  padding: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    margin: 45px auto 0px;
  }
`;

const StyledButton = styled(Button)`
  text-transform: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  ${(props) => !props.isAvailable && `
    border-color: ${colorsV3.lightGrey3};
    color: ${colorsV3.lightGrey3};
    pointer-events: none;
    cursor: unset;`}

  @media (max-width: ${mobileThresholdPixels}) {
    height: 45px;
    font-size: 13px;
    min-width: 210px;
    padding: 10px;
    margin: 10px 37px 0px;
    ${(props) => props.mobileOrder && 'margin-top: 0px;'}
  }
`;

const StyledLink = styled.div`
  text-decoration: none;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

const WhiteBlock = styled.div`
  background-color: ${colorsV3.white};
  padding: 16px 60px;
`;

const H3 = styled.h3`
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const H4 = styled.h4`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const H5 = styled.h5`
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const StyledLink2 = styled.div`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
  @media (max-width: ${mobileThresholdPixels}) {
    order: -1;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const P = styled.p`
  padding-left: 40px;
  position: relative;
`;

const P2 = styled.p`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  position: absolute;
  top: -50px;
`;

const ProgressBarVerticalContainer = styled.div`
  position: relative;
  top: 14px;
`;

const renderProgressBar = (height = 0) => (
  <ProgressBarVerticalContainer>
    <ProgressBarVertical isActive height={height} />
  </ProgressBarVerticalContainer>
);

const renderAtShopWording = (goNext) => (
  <div>
    <PageTitle>Programmer la venue de votre Tilliste</PageTitle>
    <WhiteBlock>
      <H3>Optimiser les pick Up ! </H3>
      <H4>Donnez plusieurs commandes au Tilliste lors de son passage.</H4>
      <H5>Quel dommage de le faire se déplacer pour une seule commande client. Un trajet de trop pour notre chère planète. </H5>
      <Row>
        {renderProgressBar(0)}
        <P>
          <b>Gagnez du temps :</b>
          {' '}
          Associer à chaque vente et à chaque vêtement à retoucher mis de coté les les infos retouches & clients
          (Nom/Prénom, Mail/Tél., retouches à effectuer).
        </P>
      </Row>
      <Row>
        {renderProgressBar(0)}
        <P>Préparer le matériel propre à votre marque et nécessaire à la réparation pour la venue des couturiers.</P>
      </Row>
      <Row>
        {renderProgressBar(60)}
        <P>
          <b>Réservez le prochain passage en magasin d’un couturier</b>
          {' '}
          et continuez à collecter les retouches de vos clients jusqu’à ce jour.
        </P>
      </Row>
      <Row>
        {renderProgressBar(170)}
        <P>
          <b>Pick Up :</b>
          <br />
          <p>
            <b>Pointage des retouches par votre Tilliste depuis son app mobile :</b>
            {' '}
            Votre Tilliste listera avec vous sur son application le détail des différentes réparations & retouches.
          </p>
          <p>
            <b>& Validation de date de rendu</b>
            {' '}
            des vêtements lors du pointage avec votre Tilliste. Délais de 3 à 5 jours.
          </p>
        </P>
      </Row>
      <Row>
        {renderProgressBar(0)}
        <P>
          <P2>👉 Lors du rendu, vous pourrez lui redonner des affaires !</P2>
          <b>Rendu</b>
          {' '}
          des affaires retouchées. Vos clients sont automatiquement informés par mail et sms.
        </P>
      </Row>
      <StyledLink2 onClick={goNext}>
        <ArrowButton>
          C‘est tout bon
        </ArrowButton>
      </StyledLink2>
    </WhiteBlock>
  </div>
);

const Step1 = ({
  cloth, fabric, problems, lining, isLiningDisplayed, selectedRdv1Location, showAtShopWording,
  selectPiece, selectFabric, selectProblem, selectLocation, selectLining, setSelectValue, setRef, selectRdv1Location,
  renderSummary, isSelectingDone, goNext, addPiece, getTreeLocations,
}) => (
  <Wrapper>
    <Block>
      {!featureSwitch('retailShopId') && (<ProgressBar step={1} />)}

      <PageTitle>
        {featureSwitch('step1_atShopRdv')
          ? 'Commandez votre couturier Tilli'
          : 'Dites-nous ce dont votre client a besoin en 2 min chrono'}
      </PageTitle>

      {featureSwitch('step1_atShopRdv') ? (
        <Step1Rdv1Location
          selectRdv1Location={selectRdv1Location}
          selectedRdv1Location={selectedRdv1Location}
        />
      ) : null}

      {showAtShopWording && renderAtShopWording(goNext)}

      <Relative>
        <ScrollingPosition>
          <div ref={(component) => setRef('Step1Pieces', component)} />
        </ScrollingPosition>
      </Relative>

      {!showAtShopWording && (!featureSwitch('step1_atShopRdv') || selectedRdv1Location) ? (
        <Step1Pieces
          pieces={clothes}
          selectPiece={selectPiece}
          selectedPiece={cloth}
        />
      ) : null}

      <Relative>
        <ScrollingPosition>
          <div ref={(component) => setRef('Step1Fabrics', component)} />
        </ScrollingPosition>
      </Relative>

      {!!cloth && cloth !== 'mari'
        && (
          <Step1Fabrics
            selectFabric={selectFabric}
            selectedFabric={fabric}
            selectedCloth={cloth}
          />
        )}

      <Relative>
        <ScrollingPosition mobileLow>
          <div ref={(component) => setRef('Step1Problems', component)} />
        </ScrollingPosition>
      </Relative>

      {(!!fabric || cloth === 'mari')
        && (
          <Step1Problems
            selectedProblems={problems}
            selectProblem={(slug) => selectProblem(slug, getTreeLocations(slug))}
            treeProblems={getTreeProblems(tree, cloth)}
            selectedCloth={cloth}
          />
        )}

      {!!problems
        && Object.keys(problems)
          .filter((problem) => problem !== 'talkRDV' && !problem.includes('_multiple') && getTreeLocations(problem).locations.length > 1)
          .map((problem) => (
            <Step1Locations
              key={problem}
              selectedLocations={problems[problem]}
              selectLocation={(slug) => selectLocation(problem, slug)}
              treeLocations={getTreeLocations(problem)}
            />
          ))}

      {!!problems
        && Object.keys(problems).map((problem, index) => problem.includes('_multiple') && problems[problem].length > 0
          ? (
            <Step1Multiple
              key={problem}
              slug={problem}
              createRange={createRange}
              setSelectValue={setSelectValue}
              selectedValue={problems[problem][0]}
              isLast={cloth === 'acce' && index === Object.keys(problems).length - 1}
            />
          )
          : <div key={problem} />)}

      {isLiningDisplayed
        && (
          <Step1Lining
            selectLining={selectLining}
            selectedLining={lining}
          />
        )}

      {renderSummary()}

      {isSelectingDone && (
        <StepContainer2>
          <ComponentsContainer>
            <StyledButton onClick={addPiece} transparent isAvailable>
              + Ajouter une pièce
            </StyledButton>
            <StyledLink onClick={goNext}>
              <ArrowButton>
                {featureSwitch('retailShopId') ? 'Etape suivante' : 'OK, on passe au RDV !'}
              </ArrowButton>
            </StyledLink>
          </ComponentsContainer>
        </StepContainer2>
      )}
    </Block>
  </Wrapper>
);

Step1.propTypes = {
  cloth: PropTypes.string.isRequired,
  fabric: PropTypes.string.isRequired,
  problems: PropTypes.shape({}).isRequired,
  lining: PropTypes.string.isRequired,
  isLiningDisplayed: PropTypes.bool.isRequired,
  selectedRdv1Location: PropTypes.string,
  showAtShopWording: PropTypes.bool,
  selectPiece: PropTypes.func.isRequired,
  selectFabric: PropTypes.func.isRequired,
  selectProblem: PropTypes.func.isRequired,
  selectLocation: PropTypes.func.isRequired,
  selectLining: PropTypes.func.isRequired,
  selectRdv1Location: PropTypes.func.isRequired,
  setSelectValue: PropTypes.func.isRequired,
  setRef: PropTypes.func.isRequired,
  renderSummary: PropTypes.func.isRequired,
  isSelectingDone: PropTypes.bool,
  goNext: PropTypes.func.isRequired,
  addPiece: PropTypes.func.isRequired,
  getTreeLocations: PropTypes.func.isRequired,
};

Step1.defaultProps = {
  isSelectingDone: false,
  selectedRdv1Location: null,
  showAtShopWording: false,
};

export default Step1;
